import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from 'src/app/services/httpServices';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {


    public pageData: any = [{ Link: null, Alan1: null, Alan2: null, Alan3: null, LinkAdi: null }];
    public id: any;
    constructor(public dataService: DataService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit() {
      //  debugger;

     
        this.route.params.subscribe(params => {

            if (params['magaza'] != undefined) {
                this.id = params['magaza'];
                //this.isFirmaOrCat.emit(true);
            }
            if (params['category'] != undefined) {
                this.id = params['category'];
                //this.isFirmaOrCat.emit(true);
            }

            if (params['category'] == undefined && params['magaza'] == undefined) {
                console.log("COLLECTION COMPONENT");
                //this.isFirmaOrCat.emit(false);
            }

  

        });
        //debugger;
        this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
            const refresh = paramMap.get('refresh');
          //  debugger;
            if (refresh) {
            //    debugger;
                this.dataService.postFuncP('Sayfa/GetCollection', this.id)
                    .subscribe((data: any) => {
                        if (data.resultType == 1) {
                            this.pageData = data.innerData;
                            console.log("COLLECTION COMPONENT", this.pageData);
                            //this.product.pictures = this.product.pictures.$values;
                            //this.product.urunDropDown = this.product.urunDropDown.$values;
                            //this.product.urunList = this.product.urunList.$values;
                        }
                        else {
                            //this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata');
                        }
                    },
                        error => '',//this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata'),
                        () => '');
            }
        });
      //  debugger;
      //  this.dataService.postFuncP('Sayfa/GetCollection', this.id)
        //    .subscribe((data: any) => {
          //      if (data.resultType == 1) {
            //        debugger;
              //      this.pageData = data.innerData;
                    //this.product.pictures = this.product.pictures.$values;
                    //this.product.urunDropDown = this.product.urunDropDown.$values;
                    //this.product.urunList = this.product.urunList.$values;
               // }
                //else {
                    //this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata');
               // }
           // },
             //   error => '',//this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata'),
              //  () => '');
    }

}
