import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../../shared/services/products.service';

@Component({
  selector: 'product-details-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    category: any;
    constructor(public productsService: ProductsService) {
    }
 

    ngOnInit() {
        //this.category = productsService.CategoryGet();
  }

}
