import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';
import { DataService } from '../services/httpServices';
// Services
import { WINDOW_PROVIDERS } from "./services/windows.service";
import { LandingFixService } from '../shared/services/landing-fix.service';
import { InstagramService } from "./services/instagram.service";
import { ProductsService } from "./services/products.service";
import { CartService } from "./services/cart.service";
import { OrderService } from "./services/order.service";
import { PaginationService } from "./classes/paginate";
// Pipes
import { OrderByPipe } from './pipes/order-by.pipe';
// components
import { HeaderOneComponent } from './header/header-one/header-one.component';

import { TopbarOneComponent } from './header/widgets/topbar/topbar-one/topbar-one.component';
import { NavbarComponent } from './header/widgets/navbar/navbar.component';
import { SettingsComponent } from './header/widgets/settings/settings.component';
import { FooterTwoComponent } from './footer/footer-two/footer-two.component';
import { InformationComponent } from './footer/widgets/information/information.component';
import { CategoriesComponent } from './footer/widgets/categories/categories.component';
import { WhyWeChooseComponent } from './footer/widgets/why-we-choose/why-we-choose.component';
import { CopyrightComponent } from './footer/widgets/copyright/copyright.component';
import { SocialComponent } from './footer/widgets/social/social.component';
import { FeatherIconsComponent } from './feather-icons/feather-icons.component';
import { FileUploadModule } from 'ng2-file-upload';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { ButtonModule } from 'primeng/button';



import { CollectionComponent } from './../pages/collection/collection.component';

const data = require("../../../../appsettings.json");
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    maxFilesize: 5,
    url: data.Settings.UploadUrl,
    createImageThumbnails: true,
    thumbnailWidth: 200,
    thumbnailHeight: 200,
    autoProcessQueue: false
};

import { MediaUploadComponent, ButtonViewComponent } from './media-upload/media-upload.component';

@NgModule({
    exports: [
        CommonModule,
        TranslateModule,
        HeaderOneComponent,
        FooterTwoComponent,
        OrderByPipe,
        FeatherIconsComponent,
        MediaUploadComponent,
        SidebarComponent,

        CollectionComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        DropzoneModule,
        FileUploadModule,
        Ng2SmartTableModule,
        ButtonModule,
    ],
    entryComponents: [
        ButtonViewComponent
    ],
    declarations: [

        CollectionComponent,

        HeaderOneComponent,
        FooterTwoComponent,
        OrderByPipe,
        NavbarComponent,
        SettingsComponent,
        TopbarOneComponent,
        InformationComponent,
        CategoriesComponent,
        WhyWeChooseComponent,
        CopyrightComponent,
        SocialComponent,
        FeatherIconsComponent,
        MediaUploadComponent,
        ButtonViewComponent,
        SidebarComponent,
        ContentLayoutComponent
    ],
    providers: [
        WINDOW_PROVIDERS,
        LandingFixService,
        InstagramService,
        ProductsService,
        CartService,
        OrderService,
        PaginationService,
        DataService,
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG
        },
    ]
})
export class SharedModule { }
