import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../services/nav.service';
import { DataService } from '../../services/httpServices';
import { NgxPermissionsService } from 'ngx-permissions';
import { audit } from 'rxjs/operators';
 

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public menuItems: Menu[];

  public menux: Menu[];
  public url: any;
  public fileurl: any;
  user: any;
  auth = [];
  constructor(private router: Router, public navServices: NavService, private _dataService: DataService, public permissionsService: NgxPermissionsService) {
    // permissions = permissionsService.getPermissions();
    //console.log(permissions);
    //console.log('permissions');


    this._dataService.postFunc('Kullanici/UserSidebarInfo')
      .subscribe((data: any) => {
        this.user = data.innerData[0];

        //Yetki kayboldugu için burada tekrar doldurduk
        this.auth.push(this.user.auth);
        permissionsService.loadPermissions(this.auth);


        this.navServices.items.subscribe(menuItems => {
          menuItems.forEach(item => {

            var visible = false;
            if (item.perm != null) {
              item.perm.forEach(perm => {
                var data = permissionsService.getPermission(perm);
                if (data != null) {
                  visible = true;
                }
              });
            }
            item.visible = visible;

          });
          this.menuItems = menuItems;


          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              menuItems.filter(items => {
                if (items.path === event.url)
                  this.setNavActive(items)
                if (!items.children) return false
                items.children.filter(subItems => {
                  if (subItems.path === event.url)
                    this.setNavActive(subItems)
                  if (!subItems.children) return false
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url)
                      this.setNavActive(subSubItems)
                  })
                })
              })
            }
          })
        })
      },
        error => '',
        () => '');
  

  
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  ngOnInit() {
    
  }


  getUserInfo() {
 
  }

}
