import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../../../shared/classes/product';
import { ProductsService } from '../../../../shared/services/products.service';
import { DataService } from 'src/app/services/httpServices';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {
  
  public products : any[] = [];	

    constructor(
        private productsService: ProductsService, public dataService: DataService) { }

  
    ngOnInit() {

  	//this.productsService.getProducts().subscribe(product => this.products = product);
        this.dataService.postFuncP('Urun/GetUserProductViewList', 6)//, Guid.parse(this.id))
          .subscribe((data: any) => {
              if (data.resultType == 1) {
                  this.products = data.innerData;                  
              }
          },
              error => '',
              () => '');
  }

}
