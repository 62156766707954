import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  perm?: string[];
  visible?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class NavService {

  public screenWidth: any
  public collapseSidebar: boolean = false

  constructor(@Inject(WINDOW) private window) {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true
    }
  }

  // Windows width
  @HostListener("window:resize", ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      path: '/dashboard/default', title: 'Panel', icon: 'home', type: 'link', badgeType: 'primary', active: false, perm: ["authFirma"]
    },
    {
      title: 'Profilim', path: '/profil/company-profil', icon: 'user', type: 'link', active: false, perm: ["authBelediye", "authKullanici", "authFirma"]
    },
    {
      title: 'Genel Tanımlar', icon: 'settings', type: 'sub', active: false, children: [
        {
          title: 'Ürün', type: 'sub', children: [
            { path: '/parameter/list-parameter/71b35703-71d2-45a2-951d-9cd6c09f8c57', title: 'Ürün Kategorileri', type: 'link' },
            { path: '/parameter/list-parameter/bb6f3050-7681-4052-8909-8ebb2177167f', title: 'Ürün Durumu', type: 'link' },
            { path: '/parameter/list-parameter/1AA7C356-0DE2-485C-B42A-E83FBBD1F01B', title: 'Birimler', type: 'link' },

          ]
        },
        {
          title: 'Genel', type: 'sub', children: [
            { path: '/parameter/list-parameter/D06BEE4E-46EB-4436-9DD0-184FA1FB2B28', title: 'İl', type: 'link' },
            { path: '/parameter/list-parameter/EB4F6805-F0B4-4DAE-BF13-CF0CCF49FFA1', title: 'Banka', type: 'link' },
      

          ]
        },
      ], perm: ["authBelediye"]
    },
    {
      title: 'Firma', icon: 'codepen', type: 'sub', active: false, children: [
        { path: '/company/list-company', title: 'Firma Listesi', type: 'link' },
        { path: '/company/create-company', title: 'Firma Kayıt', type: 'link' },
      ], perm: ["authBelediye"]
    },
    {
      title: 'Banka Listesi', icon: 'dollar-sign', type: 'sub', active: false, children: [
        { path: '/bank/create-bank', title: 'Firma Banka Kayıt', type: 'link' },
      ], perm: ["authFirma"]
    },
  
    {
      title: 'Siparişlerim', path: '/orders/list-order', icon: 'camera', type: 'link', active: false, perm: ["authKullanici", "authFirma"]
    },
    {
      title: 'Ürünler', icon: 'box', type: 'sub', active: false, children: [
        { path: '/products/create-product/:id', title: 'Ürün Kayıt', type: 'link' },
        { path: '/products/list-product', title: 'Ürün Listesi', type: 'link' },
      ], perm: [ "authFirma"]
    },
    {
      title: 'Sayfalar', icon: 'clipboard', type: 'sub', active: false, children: [
        { path: '/pages/list-page', title: 'Sayfa Listesi', type: 'link' },
        { path: '/pages/create-page', title: 'Sayfa Kayıt', type: 'link' },
      ], perm: ["authBelediye"]
    },
    {
      title: 'Menü', icon: 'align-left', type: 'sub', active: false, children: [
        { path: '/menus/list-menu', title: 'Menü Listesi', type: 'link' },
        
      ], perm: ["authBelediye"]
    },
    {
      title: 'Kullanıcılar', icon: 'user-plus', type: 'sub', active: false, children: [
        { path: '/users/create-user', title: 'Kullanıcı Kayıt', type: 'link' },
      ], perm: ["authBelediye","authFirma"]
    },
    {
      title: 'Satış Portalı', path: '/portal', icon: 'log-in', type: 'link', active: false, perm: ["authBelediye", "authKullanici", "authFirma"]
    }
    ,
    {
      title: 'Çıkış', path: '/auth/login', icon: 'log-out', type: 'link', active: false, perm: ["authBelediye", "authKullanici", "authFirma"]
    }
  ]
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
