// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  megaMenuType?: string; // small, medium, large
  image?: string;
  children?: Menu[];
}
import { Injectable } from '@angular/core';

@Injectable()
export class User {
    isExpired?: boolean;
    name?: string;
    
}

export const MENUITEMS: Menu[] = [

 
	{
        title: 'Anasayfa', type: 'link', path: 'home/seven'
	},
    {
        title: 'Festival', type: 'sub', megaMenu: true, megaMenuType: 'small', children: [
          //  { path: '/home/right-sidebar/collection/;id=79d2cb4-d3cb-431a-8195-29c1008f7769', title: 'Festival 2018', type: 'link' }
            { path: '/home/right-sidebar/collection/79d2cb4-d3cb-431a-8195-29c1008f7769', title: 'Festival 2018', type: 'link' }

        ]
    },
     {
        title: 'Kategoriler',
        type: 'sub',
        children: [
            {
                path: '/home/left-sidebar/collection/52527BD6-258C-46D0-85CC-0A3B550D8BC6',
                title: 'Keçiboynuzu',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/8D53E7D1-B20C-44E8-B425-1769F83D4104',
                title: 'Helva',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/41F7277F-04AE-4FB7-80F0-180D3915CA9D',
                title: 'Kolye',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/DF8DE231-A0EA-4CA5-9ABA-1999DD7101AC',
                title: 'Bebek Tarhanası',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/7C9307FB-4854-4D49-8AC9-231AA29D8803',
                title: 'Kuskus',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/0AE1DF3D-8962-4D0F-A48D-24E64D394E78',
                title: 'Salça',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/60CF6AB9-9F42-4337-9575-3DBB71B14970',
                title: 'Baklava',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/35623CD1-F6E6-460C-916E-48D3792D5A4F',
                title: 'Gofret',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/7E0C7D82-244D-4EC7-A4EF-51F13D4C2561',
                title: 'Oklava Büzmesi',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/EF7481AF-63F9-4DE9-80D9-6D34BEADCDCC',
                title: 'Karadut Özü',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/72EDF156-3AAA-423C-9B19-753BB938D2E3',
                title: 'Pestil',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/0921128C-48C3-4A20-BA2F-781FAA092D08',
                title: 'Erişte',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/A1CC114C-AA5D-43D3-BDBE-7A76212A56C4',
                title: 'Turşu',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/962C411E-E6B0-46A3-8120-8131BD58A26E',
                title: 'Bazlama',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/D6FCFA3D-B0A3-45D0-9076-81BB521F5C8F',
                title: 'Reçel',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/8F551C27-2A5D-4DAD-B6D5-823D3E889A51',
                title: 'Beypazarı Kurusu',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/474A2092-9560-4710-966D-900CE0AE788D',
                title: 'Hatıra Paketi',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/E8858A39-89DF-4079-9D39-9C7D9E23B473',
                title: 'Kutu',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/B0039EB7-1CE8-4166-9C32-ABED4A717DEE',
                title: 'Ceviz',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/23DCFA92-67DE-472C-B2D9-B06DD44F3F59',
                title: 'Pekmez',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/B1978162-AB0E-4648-9CB1-B58612D87A5D',
                title: 'Pişmaniye',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/3CA75B60-F451-44F9-9B60-BFCC31CA25A9',
                title: 'Damat Tatlısı',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/F09CD084-7C08-4612-99F0-C0B5D074D49B',
                title: 'Sucuk',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/B3E8F32C-B34C-40A3-A8D0-C726FBB382A7',
                title: 'Cezerye',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/290A6167-8B10-4EB8-8C2C-C96C30957928',
                title: 'Tılsım',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/3BE980E2-EF57-48C4-BE06-D46519D93EB3',
                title: 'Nane',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/3E3E2F1A-E634-4B1C-A3EA-DDD3C5903C00',
                title: 'Yüzük',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/3F17C75B-D4E0-4AE2-A565-EBFCEE6EA125',
                title: 'Yaprak Sarması',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/CB110618-E0EF-4E29-BF8F-F6DF2FD3667E',
                title: 'Tarhana',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/F6DB3339-87E2-42C1-9CC8-F82BE5ACE1E0',
                title: 'Lokum',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/E9B9B3E7-AFB5-4DEC-A96E-F95916DB0950',
                title: 'Cimcik',
                type: 'link'
            },
            {
                path: '/home/left-sidebar/collection/A3BC9D95-9C18-403E-8C6F-FE452305EB25',
                title: 'Damla Sakızı',
                type: 'link'
            }
        ]
    }];
//	{
//		title: 'Sayfalar', type: 'sub', children: [
//            { path: '/home/right-sidebar/collection/all;id:08b784da-45d9-4736-90dc-d09dd8738c9b', title: 'Hakkımızda', type: 'link' },          
//           { 
//	      	title: 'Portfolio',  type: 'sub', children: [
//		      	{ path: '/pages/grid/two/column', title: 'Portfolio-2-Grid',  type: 'link' },
//		      	{ path: '/pages/grid/three/column', title: 'Portfolio-3-Grid',  type: 'link' },
//		      	{ path: '/pages/grid/four/column', title: 'Portfolio-4-Grid',  type: 'link' },
//		      	{ path: '/pages/grid/two/masonary', title: 'Masonary-2-Grid',  type: 'link' },
//		      	{ path: '/pages/grid/three/masonary', title: 'Masonary-3-Grid',  type: 'link' },
//		      	{ path: '/pages/grid/four/masonary', title: 'Masonary-4-Grid',  type: 'link' },
//		      	{ path: '/pages/fullwidth/masonary', title: 'Masonary-Fullwidth',  type: 'link' }
//	      	]
//	      },         
//	      { path: '/pages/dashboard', title: 'dashboard', type: 'link' },  
//	      { path: '/pages/cart', title: 'cart', type: 'link' },  
//	      { path: '/pages/wishlist', title: 'wishlist', type: 'link' },    
//	      { path: '/pages/compare', title: 'compare', type: 'link' },  
//	      { path: '/pages/checkout', title: 'checkout', type: 'link' },  
//	      { path: '/pages/login', title: 'login', type: 'link' },        
//	      { path: '/pages/register', title: 'register', type: 'link' },        
//	      { path: '/pages/forgetpassword', title: 'forget-password', type: 'link' },  
//	      { path: '/pages/search', title: 'search', type: 'link' },        
//	      { path: '/pages/collection', title: 'collection', type: 'link' },  
//	      { path: '/pages/order-success', title: 'order-success', type: 'link' },  
//	      { path: '/pages/contact', title: 'contact', type: 'link' },  
//	      { path: '/pages/faq', title: 'FAQ', type: 'link' },  
//	      { path: '/pages/404', title: '404', type: 'link'}        
//	    ]
//	},
//	{
//		title: 'shop', type: 'sub', megaMenu: true, megaMenuType: 'large', children: [
//	      { 
//	      	title: 'mens-fashion',  type: 'link', children: [
//		      	{ path: '/home/left-sidebar/collection/all', title: 'sports-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'top',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'bottom',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'ethic-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'sports-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'shirts',  type: 'link' }
//	      	]
//	      },
//	      { 
//	      	title: 'women-fashion',  type: 'link', children: [
//		      	{ path: '/home/left-sidebar/collection/all', title: 'dresses',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'skirts',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'westarn-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'ethic-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'sports-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'bottom-wear',  type: 'link' }
//	      	]
//	      },
//	      { 
//	      	title: 'kids-fashion',  type: 'link', children: [
//		      	{ path: '/home/left-sidebar/collection/all', title: 'sports-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'ethic-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'sports-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'top',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'bottom-wear',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'ethic-wear',  type: 'link' }
//	      	]
//	      },
//	      { 
//	      	title: 'accessories',  type: 'link', children: [
//		      	{ path: '/home/left-sidebar/collection/all', title: 'fashion-jewellery',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'caps-and-hats',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'precious-jewellery',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'necklaces',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'earrings',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'rings-wrist-wear',  type: 'link' }
//	      	]
//	      },
//	      { 
//	      	title: 'men-accessories',  type: 'link', children: [
//		      	{ path: '/home/left-sidebar/collection/all', title: 'ties',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'cufflinks',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'pockets-squares',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'helmets',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'scarves',  type: 'link' },
//		      	{ path: '/home/left-sidebar/collection/all', title: 'phone-cases',  type: 'link' }
//	      	]
//	      },
//	    ]
//	},
//]