import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './guards/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';

export const rootRouterConfig: Routes = [
    {
        path: '',
        redirectTo: 'home/seven',
        pathMatch: 'full'
    },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: 'home',
                loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
            },
            {
                path: 'pages',
                loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'home/seven'
    }
];

