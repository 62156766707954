import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShopRoutingModule } from './shop-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BarRatingModule } from "ngx-bar-rating";
import { RangeSliderModule } from 'ngx-rangeslider-component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPayPalModule } from 'ngx-paypal';
import { DataService } from '../services/httpServices';
import { NgxImgZoomModule } from 'ngx-img-zoom';

// Home-seven components
import { HomeSevenComponent } from './home-7/home-seven.component';
import { SliderSevenComponent } from './home-7/slider/slider.component';
import { CollectionBannerSevenComponent } from './home-7/collection-banner/collection-banner.component';
import { SpecialProductsSevenComponent } from './home-7/special-products/special-products.component';
import { ProductTabSevenComponent } from './home-7/product-tab/product-tab.component';
import { ProductSliderSevenComponent } from './home-7/product-slider/product-slider.component';
import { ServicesSevenComponent } from './home-7/services/services.component';
import { InstagramSevenComponent } from './home-7/instagram/instagram.component';

// Products Components 
import { ProductComponent } from './product/product.component';
import { ProductBoxComponent } from './product/product-box/product-box.component';
import { ProductBoxHoverComponent } from './product/product-box-hover/product-box-hover.component';
import { ProductBoxVerticalComponent } from './product/product-box-vertical/product-box-vertical.component';
import { ProductBoxMetroComponent } from './product/product-box-metro/product-box-metro.component';
import { CollectionLeftSidebarComponent } from './product/collection/collection-left-sidebar/collection-left-sidebar.component';
import { CollectionRightSidebarComponent } from './product/collection/collection-right-sidebar/collection-right-sidebar.component';
import { CollectionNoSidebarComponent } from './product/collection/collection-no-sidebar/collection-no-sidebar.component';
import { ColorComponent } from './product/collection/filter/color/color.component';
import { BrandComponent } from './product/collection/filter/brand/brand.component';
import { PriceComponent } from './product/collection/filter/price/price.component';
import { ProductRightSidebarComponent } from './product/product-details/product-right-sidebar/product-right-sidebar.component';
import { RelatedProductsComponent } from './product/product-details/related-products/related-products.component';
import { SidebarComponent } from './product/product-details/sidebar/sidebar.component';
import { CategoriesComponent } from './product/widgets/categories/categories.component';
import { QuickViewComponent } from './product/widgets/quick-view/quick-view.component';
import { ModalCartComponent } from './product/widgets/modal-cart/modal-cart.component';
import { NewProductComponent } from './product/widgets/new-product/new-product.component';
import { ProductCompareComponent } from './product/product-compare/product-compare.component';
import { CartComponent } from './product/cart/cart.component';
import { CheckoutComponent } from './product/checkout/checkout.component';
import { SuccessComponent } from './product/success/success.component';
import { ExitPopupComponent } from './product/widgets/exit-popup/exit-popup.component';
import { AgeVerificationComponent } from './product/widgets/age-verification/age-verification.component';
import { NewsletterComponent } from './product/widgets/newsletter/newsletter.component';
import { PlyrModule } from 'ngx-plyr';

import { NgxPermissionsModule } from 'ngx-permissions';


//import { CollectionComponent } from './../pages/collection/collection.component';

@NgModule({
    exports: [ExitPopupComponent,
        //CollectionComponent,
        NgxPermissionsModule],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ShopRoutingModule,
        SharedModule,
        SlickCarouselModule,
        BarRatingModule,
        RangeSliderModule,
        InfiniteScrollModule,
        NgxPayPalModule,
        NgxImgZoomModule,
        PlyrModule,
        NgxPermissionsModule
    ],
    declarations: [
        // Home Seven
        HomeSevenComponent,
        SliderSevenComponent,
        CollectionBannerSevenComponent,
        SpecialProductsSevenComponent,
        ProductTabSevenComponent,
        ProductSliderSevenComponent,
        ServicesSevenComponent,
        InstagramSevenComponent,
        // Product
        ProductComponent,
        ProductBoxComponent,
        ProductBoxHoverComponent,
        ProductBoxVerticalComponent,
        ProductBoxMetroComponent,
        CollectionLeftSidebarComponent,
        CollectionRightSidebarComponent,
        CollectionNoSidebarComponent,
        ColorComponent,
        BrandComponent,
        PriceComponent,
        ProductRightSidebarComponent,
        RelatedProductsComponent,
        SidebarComponent,
        CategoriesComponent,
        QuickViewComponent,
        ModalCartComponent,
        NewProductComponent,
        ProductCompareComponent,
        CartComponent,
        CheckoutComponent,
        SuccessComponent,
        ExitPopupComponent,
        AgeVerificationComponent,
        NewsletterComponent
        //,CollectionComponent
    ],
    providers: [
        DataService
    ]
})
export class ShopModule { }
