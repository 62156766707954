
export enum ParametreType {
  UrunDurum = 'bb6f3050-7681-4052-8909-8ebb2177167f',
  Kampanya = '92ac368c-de0b-4018-aa31-5670da60f410',
  KargoTipi = 'cb8c0357-1e21-46b1-9949-819f614aaf76',
  UrunKategori = '71b35703-71d2-45a2-951d-9cd6c09f8c57',
  Bankalar = 'EB4F6805-F0B4-4DAE-BF13-CF0CCF49FFA1',
  Birim = '1aa7c356-0de2-485c-b42a-e83fbbd1f01b',
  SayfaTip = '38e97858-b6d4-4a92-ad65-2d585a529de4',
  Iller = 'd06bee4e-46eb-4436-9dd0-184fa1fb2b28'
}

export enum SiparisType {
  SiparisIptal = '2d4ce931-05ff-435d-8491-1cf148976216',
  SiparisHazirlaniyor = '85e179b8-3c6e-4940-9d22-76323ea5e0d5',
  SiparisOlusturuldu = '72bd6a56-261c-4fa9-bed3-c0bb079c7f48',
  SiparisGonderildi = '3f016cb5-4c2f-408f-9142-e0efa99b5c66',
  HavaleBildirimi = '936b5cd6-8f34-47ef-b15f-e4293d0b398b',
}

export enum IslemDurum {
  OnayBekleniyor = 'f20c6460-5e22-41f6-a3fb-d88dc317ce5e',
  Onaylandi = '660884e4-a3e2-4772-819b-55a1a543efab'
}


export enum SayfaType {
  Icerik = '06e809c0-d01a-4be9-8ff2-653215bcd70a',
  Etkinlik = '958913a3-ac94-4dc1-8c22-9657f879eef9',
  Aktivite = '026a8a52-a638-4b6c-8189-9ef3dc4de9db',
  Duyuru = 'bc629843-ca8f-450e-b393-a7076b9ebb51',
  FotografGalerisi = '2664ccca-1fa8-4dd2-950f-fa25d2f42943',
  VideoGaleri = '88a92b6a-aa1c-4b3e-92ae-30fc8801a8c0'
}

export enum Iller {
    IlId ='d06bee4e-46eb-4436-9dd0-184fa1fb2b28'
}


export enum Menux {
    Anasayfa = '08b784da-45d9-4736-90dc-d09dd8738c91',
    Kategori = '08b784da-45d9-4736-90dc-d09dd8731c91',
    Magaza = '08b784da-45d9-4736-90dc-d19dd8731c91'
}