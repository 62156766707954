import { Component, OnInit } from '@angular/core';
import { Product } from '../../../../classes/product';
import { ProductsService } from '../../../../../shared/services/products.service';
import { Observable, of } from 'rxjs';
import { DataService } from '../../../../../services/httpServices';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../.././navbar/navbar-items';
const jwtHelper = new JwtHelperService();

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar-one.component.html',
  styleUrls: ['./topbar-one.component.scss']
})
export class TopbarOneComponent implements OnInit {
 
    constructor(public productsService: ProductsService, private _dataService: DataService, private cookieService: CookieService, private route: ActivatedRoute, private router: Router, public user: User) {
    }
  

    ngOnInit() {
        let currentToken = this.cookieService.check('beypazariWebToken') ? JSON.parse(this.cookieService.get('beypazariWebToken')) : null;
        this.user.isExpired = jwtHelper.isTokenExpired(currentToken);
        console.log("expired:" + this.user.isExpired);
        if (this.user.isExpired) {
            this.cookieService.delete('beypazariWebToken');
            this.cookieService.delete('beypazariPermission');
        }
    }


    logout() {
        this.cookieService.delete('beypazariWebToken');
        this.cookieService.delete('beypazariPermission');
        this.user.name = null;
        this.user.isExpired = true;
        this.router.navigate(['/home/seven']);
    }

}
