import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Utility } from '../utility/utility';

@Injectable()
export class SettingsService {

  private IdIslem: any;
  private IdIslemTip: any;
  private KapakResmi: any;

  constructor() { 
    this.KapakResmi = '';
  }


  setIdIslemAndIslemTip(idIslem, idIslemTip) {

    if (Utility.IsGUIDAndNotEqualBlankID(idIslem)) {
      this.IdIslem = idIslem;
    }

    if (Utility.IsGUIDAndNotEqualBlankID(idIslemTip)) {
      this.IdIslemTip = idIslemTip;
    }
  }

  public setKapakResmi(kapakResmi) {
    if (Utility.IsValueNullOrEmpty(kapakResmi)) 
      this.KapakResmi = kapakResmi;    
  }

  getIdIslem() {
    return this.IdIslem;
  }

  getIdIslemTip() {
    return this.IdIslemTip;
  }

  getKapakResmi() {
    return this.KapakResmi;
  }

}
