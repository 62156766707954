import { Component, OnInit } from '@angular/core';
import { Product } from '../../../shared/classes/product';
import { CartItem } from '../../../shared/classes/cart-item';
import { ProductsService } from '../../../shared/services/products.service';
import { CartService } from '../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { DataService } from 'src/app/services/httpServices';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

    public cartItems: Observable<CartItem[]> = of([]);
    public shoppingCartItems: CartItem[] = [];
    public discountCamgainTotal: any;

    constructor(private productsService: ProductsService,
        public router: Router,
        private toastr: ToastrService,
        public dataService: DataService,
        private cartService: CartService,
        private permissionsService: NgxPermissionsService,
        private cookieService: CookieService) { }

    ngOnInit() {
        this.cartItems = this.cartService.getItems();
        this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
        this.getCampaign();

    }

    // Increase Product Quantity
    public increment(product: any, quantity: number = 1) {
        this.cartService.updateCartQuantity(product, quantity);
    }

    // Decrease Product Quantity
    public decrement(product: any, quantity: number = -1) {
        this.cartService.updateCartQuantity(product, quantity);
    }

    // Get Total
    public getTotal(): Observable<number> {
        return this.cartService.getTotalAmount();
    }

    // Remove cart items
    public removeItem(item: CartItem) {
        this.cartService.removeFromCart(item);
    }

    public getCampaign() {
        let request = this.shoppingCartItems.map(function (arg) {

            let result = { Id: null, Adet: null };

            result.Id = arg.product.id;
            result.Adet = arg.quantity;

            return result;
        });//x.product.id

        this.dataService.postFuncP('Urun/GetUrunKampanya', request) //this.shoppingCartItems.map(x => x.product.id))
            .subscribe((data: any) => {
                if (data.resultType == 1) {
                    this.discountCamgainTotal = data.innerData;
                    this.discountCamgainTotal.model = data.innerData.model.$values;
                }
            },
                error => '',
                () => '');
    }

    Odeme() {

        let currentToken = this.cookieService.check('beypazariWebToken') ? JSON.parse(this.cookieService.get('beypazariWebToken')) : null;
        console.log(currentToken);
        
        if (currentToken != null) {
            let authBelediye = this.permissionsService.getPermission('authBelediye') != null;
            let authFirma = this.permissionsService.getPermission('authFirma') != null;
            let authKullanici = this.permissionsService.getPermission('authKullanici') != null;


            console.log("authBelediy" + authBelediye);
            console.log("authFirma" + authFirma);
            console.log("authKullanici" + authKullanici);
            if (authBelediye) {
                this.toastr.warning('Belediye Kullanıcıları Ödeme İşlemi Gerçekleştiremez.', 'Bilgi');
            }
            else if (authFirma) {
                this.toastr.warning('Firma Kullanıcıları Ödeme İşlemi Gerçekleştiremez.', 'Bilgi');
            }
            else if (authKullanici) {
                this.router.navigate(['/home/checkout']);
            }
            else {
                this.toastr.warning('Ödeme işlemine geçebilmek için önce giriş yapmalısınız.', 'Bilgi');
            }
        }
        else {
               this.toastr.warning('Ödeme işlemine geçebilmek için önce giriş yapmalısınız.', 'Bilgi');
        }
        
    }
}
