import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';

@Injectable()
export class Utility {

  constructor() {
  }

  static IsGUIDAndNotEqualBlankID(expression: string): boolean {
    if (expression != null && expression != '' && expression.length == 36) {

      if (expression.toString() != Guid.EMPTY && Guid.isGuid(expression))
        return true;
      else
        return false;
    }
    else {
      return false;
    }
  }

  static IsValueNullOrEmpty(value: any): boolean {

    if (value != null) {
      if (value != '' && value != ' ')
        return true;
      else
        return false;
    }
    else
      return false;
  }

  static IsNumberAndNotEqualBlank(value: any | string | number): boolean {
    return ((value != null) && !isNaN(Number(value)));
  }

  static compareDate(date1: any, date2: any): boolean {

    let startDate = new Date(date1);
    let endDate = new Date(date2);

    if (endDate.getTime() > startDate.getTime())
      return true;
    else
      return false;
  }

  static workOrderTypes: any[] = [];

  static WorkOrderTypes(): any[] {

    return this.workOrderTypes = [{ id: 0, value: 'All' }, { id: 1, value: 'Assigned' }, { id: 2, value: 'Unassigned' }];
  }

}
