import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from 'src/app/services/httpServices';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})



export class AppComponent implements OnInit {

    public perm: any = [];
    public menuItems: any = [];
    title = 'app';

    constructor(private permissionsService: NgxPermissionsService,
        private http: HttpClient,
        private cookieService: CookieService,
        public dataService: DataService) { }

    ngOnInit(): void {


        this.perm[0]=this.cookieService.get("beypazariPermission");
        this.permissionsService.loadPermissions(this.perm);
        
        console.log('perm:'+ this.perm[0]);
    
 

    }
}
