import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class DataService {

  private actionUrl: string;

  constructor(private http: HttpClient, private router: Router) {
  const data = require("../../../../appsettings.json");
  this.actionUrl = data.Settings.ApiUrl;
  }

  //Global Services Functions

  public getFuncP<T>(request: string, param: any): Observable<T> {
    return this.http.get<T>(this.actionUrl + request + param);
  }

  public getFunc<T>(request: string): Observable<T> {
    return this.http.get<T>(this.actionUrl + request);
  }

  public getFuncExt<T>(request: string): Observable<T> {
    return this.http.get<T>(request);
  }

  public postFuncP<T>(request: string, param: any): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<T>(this.actionUrl + request, JSON.stringify(param), httpOptions);
  }

  public postFunc<T>(request: string): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<T>(this.actionUrl + request, httpOptions);
  }



  public postDownloadFuncP<T>(request: string, param: any): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<T>(this.actionUrl + request, JSON.stringify(param), httpOptions);
  }

  public logout() {
    this.router.navigate(['/login'], { queryParams: { return: 'SESSION_LOST' } });
  }
}
