import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { DataService } from 'src/app/services/httpServices';
import { ParametreType } from '../../../../utility/utility-constant';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

    constructor(public router: Router,
        public route: ActivatedRoute, public dataService: DataService) { }
    categori: any[] = [];
    // collapse toggle
    ngOnInit() {

        $('.collapse-block-title').on('click', function (e) {
            e.preventDefault;
            var speed = 300;
            var thisItem = $(this).parent(),
                nextLevel = $(this).next('.collection-collapse-block-content');
            if (thisItem.hasClass('open')) {
                thisItem.removeClass('open');
                nextLevel.slideUp(speed);
            } else {
                thisItem.addClass('open');
                nextLevel.slideDown(speed);
            }
        });


        debugger;
        this.dataService.postFuncP('Parametre/GetByPId', ParametreType.UrunKategori)
            .subscribe((data: any) => {
                if (data.resultType == 1) {
                    this.categori = data.innerData;
        
                }
                else {
                    //this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata');
                }
            },
                error => '',//this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata'),
                () => '');
    }

    // For mobile view
    public mobileFilterBack() {
        $('.collection-filter').css("left", "-365px");
    }

    onClick(event) {
        this.router.navigate(['/home/left-sidebar/collection/'+ event]);
    }
}
