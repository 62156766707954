import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../../../../shared/classes/product';
import { ProductsService } from '../../../../shared/services/products.service';
import { CartService } from '../../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import * as $ from 'jquery';
import { DataService } from 'src/app/services/httpServices';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'app-product-right-sidebar',
    templateUrl: './product-right-sidebar.component.html',
    styleUrls: ['./product-right-sidebar.component.scss']
})
export class ProductRightSidebarComponent implements OnInit {

    public product: any = {};
    public products: Product[] = [];
    public counter: number = 1;
    public selectedSize: any = '';

    id: any;

    modelcompany: any;

    //Get Product By Id
    constructor(private route: ActivatedRoute, private router: Router,
        public productsService: ProductsService,
        public dataService: DataService,
        private cartService: CartService,
        private cookieService: CookieService,
        private toastrService: ToastrService,
        private permissionsService: NgxPermissionsService) {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            //this.productsService.getProduct(id).subscribe(product => this.product = product)
        });
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            //this.productsService.getProduct(id).subscribe(product => this.product = product)
        });

        this.dataService.postFuncP('UrunDetay/GetFormById', this.id)//, Guid.parse(this.id))
            .subscribe((data: any) => {
                if (data.resultType == 1) {
                    this.product = data.innerData;
                    this.product.urunDropDown = this.product.urunDropDown.$values;
                    this.product.pictures = this.product.pictures.$values;
                    this.productsService.CategorySet(this.product.category);
                    this.slideNavConfig.slidesToShow = this.product.pictures.length;
                }
                else {
                    //this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata');
                }
            },
                error => '',//this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata'),
                () => '');

        //this.productsService.getProducts().subscribe(product => this.products = product);
    }
    tempId: any;

    onChange(Event) {
        let index = this.product.urunDropDown.map(x => x.id).indexOf(Event.target.value);
        this.product.birim = this.product.urunDropDown[index].birim;
        this.product.salePrice = this.product.urunDropDown[index].salePrice;
        this.product.price = this.product.urunDropDown[index].price;
        this.product.id = this.product.urunDropDown[index].id;
    }

    public slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
    };

    public slideNavConfig = {
        vertical: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        asNavFor: '.product-slick',
        arrows: false,
        dots: false,
        focusOnSelect: true
    }

    // For mobile filter view
    public mobileSidebar() {
        $('.collection-filter').css("left", "-15px");
    }

    public increment() {
        this.counter += 1;
    }

    public decrement() {
        if (this.counter > 1) {
            this.counter -= 1;
        }
    }

    PictureCount: any = 0;

    // Add to cart
    public addToCart(product: Product, quantity) {
        if (quantity == 0) return false;
        this.cartService.addToCart(product, parseInt(quantity));
    }

    // Add to cart
    public buyNow(product: Product, quantity) {
        if (quantity > 0)
            this.cartService.addToCart(product, parseInt(quantity));

        let authKullanici = this.permissionsService.getPermission('authKullanici') != null;
        if (authKullanici) {
            this.router.navigate(['/home/checkout']);
        }
        else {
            this.toastrService.warning('Satın Alma İşlemi İçin Üye Girişi Yapmalısınız! ');
            setTimeout(this.routePage.bind(this), 1000);
        }
    }


    // Change size variant
    public changeSizeVariant(variant) {
        this.selectedSize = variant;
    }

    routePage() {
        this.router.navigate(['/pages/login']);
    }

}
