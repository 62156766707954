import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
// import {  IPayPalConfig,  ICreateOrderRequest } from 'ngx-paypal';
import { CartItem } from '../../../shared/classes/cart-item';
import { ProductsService } from '../../../shared/services/products.service';
import { CartService } from '../../../shared/services/cart.service';
import { OrderService } from '../../../shared/services/order.service';
import { Observable, of } from 'rxjs';
import { DataService } from 'src/app/services/httpServices';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';
import { Guid } from 'guid-typescript';
import { Utility } from 'src/app/utility/utility';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

    // form group
    public checkoutForm: FormGroup;
    public cartItems: Observable<CartItem[]> = of([]);
    public checkOutItems: CartItem[] = [];
    public orderDetails: any[] = [];
    public amount: number;
    public modelCheckOut: any = { address: null, cargoAddress: null };
    bankInfoList: any[];
    public  checkOutModelList: any = [];
    public discountCamgainTotal: any = {
        toplamIndirimliFiyat: 0, model: {}
    };


    // Form Validator
    constructor(private fb: FormBuilder, private cartService: CartService,
        public router: Router,
        public dataService: DataService,
        private toastr: ToastrService,
        public productsService: ProductsService, private orderService: OrderService) {

        this.checkoutForm = this.fb.group({
            'address': [null, Validators.required],
            'cargoAddress': [null, Validators.required],
            //phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
            //email: ['', [Validators.required, Validators.email]],
            //country: ['', Validators.required],
            //town: ['', Validators.required],
            //state: ['', Validators.required],
            //postalcode: ['', Validators.required]
        })
    }

    ngOnInit() {
        this.cartItems = this.cartService.getItems();
        this.cartItems.subscribe(products => this.checkOutItems = products);
        this.getTotal().subscribe(amount => this.amount = amount);
        this.getDefinition();
        this.getIndirimliTutar();
    }

    getIndirimliTutar() {
        this.dataService.postFuncP('Urun/GetUrunKampanya', this.checkOutItems) //this.shoppingCartItems.map(x => x.product.id))
            .subscribe((data: any) => {
                if (data.resultType == 1) {
                    this.discountCamgainTotal = data.innerData;
                    this.discountCamgainTotal.model = data.innerData.model.$values;
                }
            },
                error => '',
                () => '');
    }

    // Get sub Total
    public getTotal(): Observable<number> {
        return this.cartService.getTotalAmount();
    }

    userAddressList: any = [];
    userCargoAddressList: any = [];

    getDefinition() {

        var productId = this.checkOutItems[0].product.id;

        if (Utility.IsGUIDAndNotEqualBlankID(productId.toString())) {

            this.dataService.postFuncP('FirmaBanka/GetFirmaBankaByUrunId', productId.toString())
                .subscribe((data: any) => {
                    if (data.resultType == 1)
                        this.bankInfoList = data.innerData;
                    else if (data.resultType == 2 || data.resultType == 0)
                        this.toastr.warning('Firma banka bilgileri getirilemedi!', 'Uyarı');

                },
                    error => this.toastr.error('Hata Meydana Geldi. Lütfen Daha Sonra Tekrar Deneyiniz.', 'Hata'),
                    () => '');
        }



        this.dataService.postFunc('KullaniciAdres/GetAdresById')
            .subscribe((data: any) => {
                if (data.resultType == 1)
                    this.userAddressList = this.userCargoAddressList = data.innerData;
                else if (data.resultType == 2 || data.resultType == 0)
                    this.toastr.warning('Kullanıcı adresleri getirilemedi!', 'Uyarı');

            },
                error => this.toastr.error('Hata Meydana Geldi. Lütfen Daha Sonra Tekrar Deneyiniz.', 'Hata'),
                () => '');
    }


    showSiparisKod: boolean = false;
    SiparisKod: any;

    siparisModel: any = { siparisUrun: [], faturaAdres: null, kargoAdres: null };
    onSubmit() {


        

        if (Utility.IsGUIDAndNotEqualBlankID(this.modelCheckOut.kargoAdres) && Utility.IsGUIDAndNotEqualBlankID(this.modelCheckOut.faturaAdres)) {



            this.checkOutItems.forEach(function (datax) {

                 var checkOutModel = {
                    idKampanya: null,
                    idUrun: null,
                    idUrunFiyat: null,
                    indirimliUrunFiyati: null,
                     alinanAdet: null,
                     idFirma: null,
                    toplamTutar: null,
                    birim: null,
                    urunFiyat: null
                };

                
                checkOutModel.idKampanya = this.discountCamgainTotal.model;
                checkOutModel.idUrun = datax.product.id;
                checkOutModel.idFirma = datax.product.idFirma;
                checkOutModel.idUrunFiyat = null;
                checkOutModel.indirimliUrunFiyati = null;
                checkOutModel.alinanAdet = datax.quantity;
                checkOutModel.toplamTutar = datax.product.price;
                checkOutModel.birim = null;
                checkOutModel.urunFiyat = null;
                this.checkOutModelList.push(checkOutModel);

                console.log(this.checkOutModelList);
            },this);

         

            this.siparisModel.siparisUrun = this.checkOutModelList;
            this.siparisModel.faturaAdres = this.modelCheckOut.faturaAdres;
            this.siparisModel.kargoAdres = this.modelCheckOut.kargoAdres;

            this.dataService.postFuncP('Siparis/SaveOrUpdate', this.siparisModel)
                .subscribe((data: any) => {
                    if (data.resultType == 1) {
                        this.showSiparisKod = true;
                        this.SiparisKod = data.innerData;
                        this.toastr.success('', 'Sipariş başarıyla kaydedildi.');
                    }
                    else if (data.resultType == 2 || data.resultType == 0) {
                        this.showSiparisKod = false;
                        this.toastr.warning('', 'Uyarı');
                    }
                },
                    error => this.toastr.error('Hata Meydana Geldi. Lütfen Daha Sonra Tekrar Deneyiniz.', 'Hata'),
                    () => '');
        }
        else
            this.toastr.warning('Fatura adresi ve kargo adresi girilmelidir.', 'Uyari')


    }

    addressInfo: any;

    onChangeAddress(event) {
        if (Utility.IsValueNullOrEmpty(event)) {
            this.modelCheckOut.faturaAdres = event;
            this.addressInfo = this.userAddressList.filter(x => x.id == event).map(m => m.adres)[0];
        }
    }

    cargoAddressInfo: any;

    onChangeCargoAddress(event) {
        if (Utility.IsValueNullOrEmpty(event)) {
            this.modelCheckOut.kargoAdres = event;
            this.cargoAddressInfo = this.userAddressList.filter(x => x.id == event).map(m => m.adres)[0];
        }
    }

    productCampaignList: any[] = [];

    YeniAdres() {

        this.dataService.postFunc('Kullanici/GetKullaniciId')
            .subscribe((data: any) => {
                if (data.resultType == 1)
                    this.router.navigate(['/admin/create-user-address', { 'idKullanici': data.innerData }]);
                else if (data.resultType == 2 || data.resultType == 0)
                    this.toastr.warning('İşleme devam edebilmek için önce giriş yapmalısınız.', 'Uyarı');

            },
                error => this.toastr.error('Hata Meydana Geldi. Lütfen Daha Sonra Tekrar Deneyiniz.', 'Hata'),
            () => '');       
    }

}
