import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Plyr from 'plyr';
import { DataService } from 'src/app/services/httpServices';
import { ToastrService } from 'ngx-toastr';
import { trigger, transition, style, animate } from "@angular/animations";
import * as _ from 'lodash'
import * as $ from 'jquery';
import { PlyrComponent } from 'ngx-plyr';

//import { Guid } from 'guid-typescript'

@Component({
    selector: 'app-collection-right-sidebar',
    templateUrl: './collection-right-sidebar.component.html',
    styleUrls: ['./collection-right-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [  // angular animation
        trigger('Animation', [
            transition('* => fadeOut', [
                style({ opacity: 0.1 }),
                animate(1000, style({ opacity: 0.1 }))
            ]),
            transition('* => fadeIn', [
                style({ opacity: 0.1 }),
                animate(1000, style({ opacity: 0.1 }))
            ])
        ])
    ]
})
export class CollectionRightSidebarComponent implements OnInit {


    public colorFilters: any[] = [];
    public tagsFilters: any[] = [];
    public tags: any[] = [];
    public colors: any[] = [];

    public animation: any;   // Animation
    public pageData: any;

    public toaster: any;

    @ViewChild(PlyrComponent, { static: true })
    plyr: PlyrComponent;


    constructor(private route: ActivatedRoute, private router: Router,
        private toastr: ToastrService,
        public dataService: DataService, ) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            const id = params['id'];
            this.getPage(id);
        });
    }


    public getPage(id) {

        debugger;
        this.dataService.postFuncP('Sayfa/GetWebUISayfaByID', id)
            .subscribe((data: any) => {

                if (data.resultType === 1) {
                    debugger;
                    this.pageData = data.innerData;
                    console.log(this.pageData);
                }
                else {
                    this.toastr.warning(data.message, 'UYARI');
                }
            },
                error => this.toastr.error('Hata Meydana Geldi. Lütfen Daha Sonra Tekrar Deneyiniz.', 'Hata'),
                () => '');
    }
    // Get current product tags



    // Animation Effect fadeIn
    public fadeIn() {
        this.animation = 'fadeIn';
    }

    // Animation Effect fadeOut
    public fadeOut() {
        this.animation = 'fadeOut';
    }

    // Initialize filetr Items


    // Update tags filter
    public updateTagFilters(tags: any[]) {
        this.tagsFilters = tags;
        this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
    }

    // Update color filter
    public updateColorFilters(colors: any[]) {
        this.colorFilters = colors;
        this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
    }


    public twoCol() {
        if ($('.product-wrapper-grid').hasClass("list-view")) { } else {
            $(".product-wrapper-grid").children().children().children().removeClass();
            $(".product-wrapper-grid").children().children().children().addClass("col-lg-6");
        }
    }

    public threeCol() {
        if ($('.product-wrapper-grid').hasClass("list-view")) { } else {
            $(".product-wrapper-grid").children().children().children().removeClass();
            $(".product-wrapper-grid").children().children().children().addClass("col-lg-4");
        }
    }

    public fourCol() {
        if ($('.product-wrapper-grid').hasClass("list-view")) { } else {
            $(".product-wrapper-grid").children().children().children().removeClass();
            $(".product-wrapper-grid").children().children().children().addClass("col-lg-3");
        }
    }

    public sixCol() {
        if ($('.product-wrapper-grid').hasClass("list-view")) { } else {
            $(".product-wrapper-grid").children().children().children().removeClass();
            $(".product-wrapper-grid").children().children().children().addClass("col-lg-2");
        }
    }

    // For mobile filter view
    public mobileFilterBack() {
        $('.collection-filter').css("left", "-15px");
    }





}
