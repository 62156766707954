import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter, ElementRef } from '@angular/core';
import { DropzoneConfigInterface, DropzoneDirective, } from 'ngx-dropzone-wrapper';
import { Guid } from 'guid-typescript';
import { SettingsService } from '../../settings/settings.service';
import { interval, Subscription } from 'rxjs';
import { LocalDataSource } from 'ng2-smart-table';
import { ViewCell } from 'ng2-smart-table';
import { Utility } from 'src/app/utility/utility';
const swal = require('sweetalert');
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/httpServices';

import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'button-view',
  template: `    
<button pButton type="button" class="{{rowData.button}}" icon="pi pi-images" (click)="onClick()"></button>
  `,
})

export class ButtonViewComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor(private settingsService: SettingsService, private toastr: ToastrService) {


  }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.settingsService.setKapakResmi(this.rowData.filename);
    this.save.emit(this.rowData);
    this.toastr.success('' + this.rowData.filename + ' Kapak resmi olarak belirlendi!', '');
  }
}


@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss']
})
export class MediaUploadComponent implements OnInit, OnChanges {

  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef<HTMLElement>;

  //@ViewChild('drpzone', { static: false }) drpzone: DropzoneDirective;
  @Input() isUpload: any;
  @Input() mediaFiles: any[];
  @Input() isProduct: any;
  @Output() isProcessDone: EventEmitter<any> = new EventEmitter();

  idIslem: any;
  idIslemTip: any;
  kapakResmi: any;
  source: LocalDataSource;
  subscription: Subscription;
  tempFile: any = { filename: null, item: null, button: null };
  tempFileList: any[] = [];

  // UPLOADER
  response: any;
  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;
  public dataappsettings: any;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  constructor(private settingsService: SettingsService, private toastr: ToastrService, public dataService: DataService) {

    //const source = interval(2000);
    //this.subscription = source.subscribe(val => this.getQueuedFiles());

    this.dataappsettings = require("../../../../../appsettings.json");
    let currentToken = JSON.parse(localStorage.getItem('currentToken'));
      debugger;
    this.uploader = new FileUploader({
      //authToken: `Bearer ${currentToken}`,
      //allowedMimeType: ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'],
     
      
      url: this.dataappsettings.Settings.UploadUrl,
      autoUpload: false
    });

    this.uploader.response.subscribe(res => {
     
        debugger;
      this.isProcessDone.emit('close');

    }
    );

    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
      this.tempFile.filename = item.file.name;
      this.tempFile.button = 'ui-button-warning';
      this.tempFile.item = item;

      this.tempFileList.push(this.tempFile);

      this.source = new LocalDataSource(this.tempFileList);

      this.tempFile = { filename: null, item: null, button: null };

    });

  }

  public settings = {
    mode: "external",
    actions: {
      columnTitle: "İşlem",
      position: 'right',
      add: false,
      delete: true,
      edit: false
    },
    columns: {
      filename: {
        title: 'Dosya Adı'
      },
    },
  };


    //,
    //button: {
    //    title: 'Kapak Resmi Yap',
    //    type: 'custom',
    //    renderComponent: ButtonViewComponent,
    //    onComponentInitFunction: (instance) => {
    //      instance.save.subscribe(row => {
    //    this.setKapakResmi(row);
    //      });
    //    }
    //  }

  // #region ESKI_UPLOAD_SETTINGS

  //public config: DropzoneConfigInterface = {
  //  clickable: true,
  //  maxFiles: 4,
  //  autoReset: null,
  //  errorReset: null,
  //  cancelReset: null,
  //  addRemoveLinks: true,
  //  //params: { idIslem: this.idIslem, idIslemTip: this.idIslemTip }

  //};

  //public onUploadInit(args: any): void {
  //  console.log('OnUploadInit');
  //  console.log(args);
  //}

  //onUploadRemove(args: any): void {
  //  console.log('onUploadRemove');
  //  console.log(args);
  //}

  //public onUploadError(args: any): void {
  //  console.log('onUploadError');
  //  console.log(args);
  //}

  //public onUploadSuccess(args: any): void {
  //  console.log('onUploadSuccess');
  //  console.log(args);
  //}

  //public onSending(args: any): void {
  //  console.log(args);

  //  const file = args[0];
  //  const formData = args[2];
  //  formData.append('idIslem', this.idIslem);
  //  formData.append('idIslemTip', this.idIslemTip);
  //  formData.append('kapakResmi', this.kapakResmi);
  //}

  //files: File[] = [];

  //onChangeFile(event) {
  //  console.log(event);
  //  this.files.push(...event.addedFiles);
  //}



  //getQueuedFiles() {
  //  if (this.drpzone) {
  //    var file = this.drpzone.dropzone().getQueuedFiles();

  //    if (file != null)
  //      if (file.length > 0) {
  //        this.tempFileList = file.map(x => x.upload);
  //        this.source = new LocalDataSource(this.tempFileList);
  //      }
  //  }
  //}

  //upload() {

  //  this.idIslem = this.settingsService.getIdIslem();
  //  this.idIslemTip = this.settingsService.getIdIslemTip();
  //  this.kapakResmi = this.settingsService.getKapakResmi();

  //  this.drpzone.dropzone().processQueue();
  //}

  // #endregion


  ngOnChanges(changes: SimpleChanges): void {

    const isUpload: SimpleChange = changes.isUpload;

    if (isUpload != null) {
      if (isUpload.currentValue != null)
        if (isUpload.currentValue)
          this.uploadFiles();
    }

    const isProduct: SimpleChange = changes.isProduct;

    if (isProduct != null) {
      if (isProduct.currentValue != null)
        if (isProduct.currentValue) {


        }
    }

    const mediaFiles: SimpleChange = changes.mediaFiles;

    if (mediaFiles != null) {
      if (mediaFiles.currentValue != null) {
        this.tempFileList = mediaFiles.currentValue;
        this.source = new LocalDataSource(mediaFiles.currentValue);
      }
    }
  }

  ngOnInit() {
      debugger;
  }

    uploadFiles() {
        debugger;
    for (var i = 0; i < this.uploader.queue.length; i++) {
      this.uploader.queue[i].headers.push(
        { name: 'IdIslem', value: this.settingsService.getIdIslem() },
        { name: 'IdIslemTip', value: this.settingsService.getIdIslemTip() },
        { name: 'KapakResmi', value: this.settingsService.getKapakResmi() },
      );
    }
    this.uploader.uploadAll();
  }

  onDeleteConfirm(event) {

    if (Utility.IsGUIDAndNotEqualBlankID(event.data.id)) {
      swal({
        title: 'Bu işlemi yapmak istediğinizden emin misiniz?',
        text: 'Silme işlem geri alınamaz!',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Vazgeç',
            value: null,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: 'Sil',
            value: true,
            visible: true,
            className: "bg-success",
            closeModal: true
          }
        }
      }).then((isConfirm) => {
        if (isConfirm) {
          this.deleteMediaFile(event.data.id);
        }
      });
    }
    else if (Utility.IsValueNullOrEmpty(event.data.item)) {
      event.data.item.remove();

      var item = this.tempFileList.filter(x => x.filename == event.data.filename)[0];

      var index = this.tempFileList.indexOf(item);

      if (index > -1) {
        this.tempFileList.splice(index, 1);
        this.source = new LocalDataSource(this.tempFileList);
      }
    }
    else
      this.toastr.warning('Yanlış parametre!', '');
  }

  setKapakResmi(newRow) {
    

    for (var i = 0; i < this.tempFileList.length; i++) {
      if (this.tempFileList[i].filename == newRow.filename) {
        this.tempFileList[i].button = 'ui-button-success';
      }
      else
        this.tempFileList[i].button = 'ui-button-warning';
    }

    this.source = new LocalDataSource(this.tempFileList);


    //var current = this.settingsService.getKapakResmi();

    //let currentItem = this.tempFileList.filter(x => x.filename = current);
    //let currentIndex = this.tempFileList.indexOf(currentItem)[0];

    //let updateItem = this.tempFileList.find(newRow);
    //let index = this.tempFileList.indexOf(updateItem)[0];

    //if (index > -1) {
    //  this.tempFileList[index].button = 'ui-button-success';
    //}

    //if (currentIndex > -1) {
    //  this.tempFileList[index].button = 'ui-button-warning';
    //}
  }

  deleteMediaFile(id) {
    if (Utility.IsGUIDAndNotEqualBlankID(id)) {

      var uri = this.isProduct ? 'Urun/DeleteUrunDosya' : 'Sayfa/DeleteSayfaDosya';

      this.dataService.postFuncP(uri, id)
        .subscribe((data: any) => {
          if (data.resultType == 1) {

            this.getProductMedia(data.innerData);

            this.toastr.success('İşlem Başarıyla Gerçekleştirildi.', '');
          }
          else if (data.resultType == 2 || data.resultType == 0) {
            this.toastr.warning(data.message, 'Uyarı');
          }
        },
          error => this.toastr.error('Hata Meydana Geldi. Lütfen Daha Sonra Tekrar Deneyiniz.', 'Hata'),
          () => '');
    }
  }

  getProductMedia(idIslem) {

    if (Utility.IsGUIDAndNotEqualBlankID(idIslem)) {

      var uri = this.isProduct ? 'Urun/GetDosyalarByUrunId' : 'Sayfa/GetDosyalarBySayfaId';

      this.dataService.postFuncP(uri, idIslem)
        .subscribe((data: any) => {
          if (data.resultType == 1) {

            if (data.innerData != null) {

              if (data.innerData.length > 0) {
                for (var i = 0; i < data.innerData.length; i++) {
                  this.tempFile.push(data.innerData.$values[i]);
                }
                this.source = new LocalDataSource(this.tempFile);
              }
              else
                this.source = new LocalDataSource([]);
            }
          }
          else if (data.resultType == 2 || data.resultType == 0) {
            this.toastr.warning(data.message, 'Uyarı');
          }
        },
          error => this.toastr.error('Hata Meydana Geldi. Lütfen Daha Sonra Tekrar Deneyiniz.', 'Hata'),
          () => '');
    }
    else
      this.toastr.warning('Yanlış parametre!', '');
  }

  //ngOnDestroy() {
  //  this.subscription.unsubscribe();
  //}
}
