import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/operator/do';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private cookieService: CookieService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        //let currentToken = JSON.parse(localStorage.getItem('beypazariWebToken'));
        let currentToken = this.cookieService.check('beypazariWebToken') ? JSON.parse(this.cookieService.get('beypazariWebToken')) : null;
        if (currentToken) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentToken}`
                }
            });
        }

        return next.handle(req).do(evt => {
            if (evt instanceof HttpResponse) {
            }
        });

    }
} 
