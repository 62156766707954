import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeSevenComponent } from './home-7/home-seven.component';
import { CollectionLeftSidebarComponent } from './product/collection/collection-left-sidebar/collection-left-sidebar.component';
import { CollectionRightSidebarComponent } from './product/collection/collection-right-sidebar/collection-right-sidebar.component';
import { CollectionNoSidebarComponent } from './product/collection/collection-no-sidebar/collection-no-sidebar.component';
import { ProductRightSidebarComponent } from './product/product-details/product-right-sidebar/product-right-sidebar.component';
import { ProductCompareComponent } from './product/product-compare/product-compare.component';
import { CartComponent } from './product/cart/cart.component';
import { CheckoutComponent } from './product/checkout/checkout.component';
import { SuccessComponent } from './product/success/success.component';


// Routes
const routes: Routes = [

    {
        path: 'seven',
        component: HomeSevenComponent
    },
    {
        path: 'left-sidebar/collection/:category',
        component: CollectionLeftSidebarComponent
    },
    {
        path: 'left-sidebar/magazacollection/:magaza',
        component: CollectionLeftSidebarComponent
    },
    {
        path: 'right-sidebar/collection/:id',
        component: CollectionRightSidebarComponent
    },
    {
        path: 'no-sidebar/collection/:category',
        component: CollectionNoSidebarComponent
    },
    {
        path: 'right-sidebar/product/:id',
        component: ProductRightSidebarComponent
    },
    {
        path: 'compare',
        component: ProductCompareComponent
    },
    {
        path: 'cart',
        component: CartComponent
    },
    {
        path: 'checkout',
        component: CheckoutComponent
    },
    {
        path: 'checkout/success',
        component: SuccessComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ShopRoutingModule { }
