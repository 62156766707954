import { Component, OnInit, ChangeDetectorRef, SimpleChanges, SimpleChange } from '@angular/core';
import { DataService } from 'src/app/services/httpServices';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;
import { Menu } from './navbar-items';
import { createTokenForExternalReference } from '@angular/compiler/src/identifiers';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  
    public menuItems: Menu[] = [];
    public kategoriler: any = [];
    public data: any = [];

    public menuItemsChild: [] = [];

    menuDChilds: any = [];
    menuKChilds: any = [];
    menuMChilds: any = [];

    paramsSub: Subscription;

    constructor(
        private route: ActivatedRoute, private router: Router,
        public dataService: DataService,
        private cookieService: CookieService,
        private cd: ChangeDetectorRef) {
    }

    ngOnInit() {

 
        this.dataService.postFunc('Menu/GetMenu').subscribe((data: any) => {

            console.log("xxx----->",data['$values']);

            this.menuItems[0] = data['$values'][2];
            this.menuItems[2] = data['$values'][3];
            this.menuItems[1] = data['$values'][1];
            this.menuItems[3] = data['$values'][0];
       
            console.log("MENU ITEMS", this.menuItems);


            for (var i = 0; i < this.menuItems[2]['children']['$values'].length; i++) {
                this.menuMChilds[i] = this.menuItems[2]['children']['$values'][i];
            }

            for (var i = 0; i < this.menuItems[1]['children']['$values'].length; i++) {
                this.menuKChilds[i] = this.menuItems[1]['children']['$values'][i];
            }

            for (var i = 0; i < this.menuItems[3]['children']['$values'].length; i++) {
                this.menuDChilds[i] = this.menuItems[3]['children']['$values'][i];
            }
   
     


            console.log(this.menuDChilds, this.menuKChilds, this.menuMChilds);

              //for (var i = 0; i < this.menuItems.length; i++) {
                  //debugger;
                //  if (this.menuItems[i]['children'] != null)
                  //{
                      
                    //  for (var j = 0; j < this.menuItems[i]['children']['$values'].length; j++)
                     // {


                         // console.log("SSS", this.menuItems[i]['children']['$values']);

                        /*  if (this.menuItems[0]['children']['$values'][0]['title'] == 'Festival')
                          {
                              this.menuDChild[i] = this.menuItems[i]['children']['$values'][j];
                          }

                          if (this.menuItems[1]['children']['$values'][0]['title'] == 'Eriste')
                          {
                              this.menuKChild[i] = this.menuItems[i]['children']['$values'][j];
                          }

                          if (this.menuItems[2]['children']['$values'][0]['title'] == 'Arabul') {
                              this.menuMChild[i] = this.menuItems[i]['children']['$values'][j];
                          } */
                      
                      
                     // }
                 // }
             
            //}
            //console.log(this.menuDChild, this.menuKChild, this.menuMChild);
       
            


            this.dataService.postFunc('Menu/GetKategoriler').subscribe((data: any) =>
            {
                this.kategoriler = data["$values"];
                console.log("KATEGORILER", this.kategoriler);
            });

        });
    }

  

    click(url) {

      /*  this.paramsSub = this.route.params.subscribe(val => {
            this.router.navigate([url]);
        });*/

        this.cd.detectChanges();
       console.log("URL--------->", url);
       this.router.navigate([url], {
            queryParams: { refresh: new Date().getTime() }
        });

       // this.router.navigate([url]);

       // this.router.navigateByUrl([url]);
  
    }

}
