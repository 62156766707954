import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-why-we-choose',
    templateUrl: './why-we-choose.component.html',
    styleUrls: ['./why-we-choose.component.scss']
})
export class WhyWeChooseComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    click(url) {
        this.router.navigate([url], {
            queryParams: { refresh: new Date().getTime() }
        });
    }
}
