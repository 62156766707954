import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { trigger, transition, style, animate } from "@angular/animations";
import { Product, ColorFilter, TagFilter } from '../../../../shared/classes/product';
import { ProductsService } from '../../../../shared/services/products.service';
import * as _ from 'lodash'
import * as $ from 'jquery';
import { DataService } from 'src/app/services/httpServices';
import { Menux } from '../../../../utility/utility-constant';
@Component({
  selector: 'app-collection-left-sidebar',
  templateUrl: './collection-left-sidebar.component.html',
  styleUrls: ['./collection-left-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [  // angular animation
    trigger('Animation', [
      transition('* => fadeOut', [
        style({ opacity: 0.1 }),
        animate(1000, style({ opacity: 0.1 }))
      ]),
      transition('* => fadeIn', [
         style({ opacity: 0.1 }),
         animate(1000, style({ opacity: 0.1 }))
      ])
    ])
  ]
})
export class CollectionLeftSidebarComponent implements OnInit {

  public products     :   Product[] = [];
  public items        :   Product[] = [];
  public allItems     :   Product[] = [];
  public colorFilters :   ColorFilter[] = [];
  public tagsFilters  :   any[] = [];
  public tags         :   any[] = [];
  public colors       :   any[] = [];
  public sortByOrder  :   string = '';   // sorting
  public animation    :   any;   // Animation

  lastKey = ''      // key to offset next query from
    finished = false  // boolean when end of data is reached

    id: any;
    magaza: any;
    isCategoryOrMagazaDetail: boolean = false;
    constructor(private route: ActivatedRoute, private router: Router,

        public dataService: DataService,  
    private productsService: ProductsService) { 
       //this.route.params.subscribe(params => {
       //   const category = params['category'];
       //   this.productsService.getProductByCategory(category).subscribe(products => {
       //      this.allItems = products  // all products
       //      this.products = products.slice(0,8)
       //      this.getTags(products)
       //      this.getColors(products)
       //   })
       //});
    
       /* this.route.params.subscribe(params => {
            this.id = params['category'];
        });*/
    }
    product: any;

    ngOnInit() {
        debugger;
        this.route.params.subscribe(params => {



        /*    if (params['category'] != Menux.Anasayfa || params['category'] != Menux.Kategori) {
                debugger;
   
                if (params['magaza'] != Menux.Magaza) {
                    this.isCategoryOrMagazaDetail = true;
                }
            }*/


          /*  if (params['category'] != Menux.Anasayfa) {
                if (params['category'] != Menux.Kategori) {
                    if (params['magaza'] != Menux.Magaza) {
                        this.isCategoryOrMagazaDetail = true;
                    }
                }
            }



            if (params['category'] != Menux.Kategori) {
                if (params['category'] != Menux.Anasayfa) {
                    if (params['magaza'] != Menux.Magaza) {
                        this.isCategoryOrMagazaDetail = true;
                    }
                }
            }



            if (params['magaza'] != Menux.Magaza) {
                if (params['category'] != Menux.Anasayfa) {            
                    if (params['category'] != Menux.Kategori) {
                        this.isCategoryOrMagazaDetail = true;
                    }
                }

                if (params['category'] != Menux.Kategori) {
                    if (params['category'] != Menux.Anasayfa) {
                        this.isCategoryOrMagazaDetail = true;
                    }
                }



            } */







            if (params['category'] == Menux.Anasayfa) {
                this.router.navigate(['/home/seven']);
           
            }

            debugger;
            this.id = params['category'];
            if (this.id) {


             
                this.dataService.postFuncP('UrunDetay/GetCategoryList', this.id)
                    .subscribe((data: any) => {
                        if (data.resultType == 1) {

                          //  this.isCategoryOrMagazaDetail = true;
                            this.product = data.innerData;
                            //this.product.pictures = this.product.pictures.$values;
                            //this.product.urunDropDown = this.product.urunDropDown.$values;
                            //this.product.urunList = this.product.urunList.$values;
                        }
                        else {
                            //this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata');
                        }
                    },
                        error => '',//this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata'),
                        () => '');
            }
            else {
                debugger;

              
                this.magaza = params['magaza'];

                this.dataService.postFuncP('UrunDetay/GetMagazaUrun', this.magaza)
                    .subscribe((data: any) => {
                        if (data.resultType == 1) {
                            //this.isCategoryOrMagazaDetail = true;


                            this.product = data.innerData;

                            //this.product.pictures = this.product.pictures.$values;
                            //this.product.urunDropDown = this.product.urunDropDown.$values;
                            //this.product.urunList = this.product.urunList.$values;
                        }
                        else {
                            //this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata');
                        }
                    },
                        error => '',//this.toastr.error('Hata Meydana Geldi Tekrar Deneyiniz.', 'Hata'),
                        () => '');
            }
        });
        
    }



  
  // Get current product tags
  public getTags(products) {
     var uniqueBrands = []
     var itemBrand = Array();
     products.map((product, index) => { 
        if(product.tags) {
           product.tags.map((tag) => {
           const index = uniqueBrands.indexOf(tag);
           if(index === -1)  uniqueBrands.push(tag);
        })
       }
     });
     for (var i = 0; i < uniqueBrands.length; i++) {
          itemBrand.push({brand:uniqueBrands[i]})
     }
     this.tags = itemBrand
  }
  
  // Get current product colors
  public getColors(products) {
     var uniqueColors = []
     var itemColor = Array();
     products.map((product, index) => {
       if(product.colors) {
       product.colors.map((color) => {
           const index = uniqueColors.indexOf(color);
           if(index === -1)  uniqueColors.push(color);
       })
      }
     });
     for (var i = 0; i < uniqueColors.length; i++) {
          itemColor.push({color:uniqueColors[i]})
     }
     this.colors = itemColor
  }

   
  // Animation Effect fadeIn
  public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

 
  //// Initialize filetr Items
  //public filterItems(): Product[] {
  //    return this.items.filter((item: Product) => {
  //        const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
  //          if(item.colors){
  //            if (item.colors.includes(curr.color)) {
  //              return prev && true;
  //            } 
  //          }
  //        }, true);
  //        const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
  //          if(item.tags) {
  //            if (item.tags.includes(curr)) {
  //              return prev && true;
  //            } 
  //          }
  //        }, true);
  //        return Colors && Tags; // return true
  //    });
  //}
  
  // Update tags filter
    public updateTagFilters(tags: any[]) {
        console.log("COLLECTION LEFT SIDE BAR UPDATE TAG FILTERS");
      this.tagsFilters = tags;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }

  // Update color filter
  public updateColorFilters(colors: ColorFilter[]) {
      this.colorFilters = colors;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }
  
  // Update price filter
  public updatePriceFilters(price: any) {
      let items: any[] = [];
      this.products.filter((item: any) => {
          if (item.price >= price[0] && item.price <= price[1] )  {            
             items.push(item); // push in array
          } 
      });
      this.items = items;
  }

  public twoCol() {
    if ($('.product-wrapper-grid').hasClass("list-view")) {} else {
      $(".product-wrapper-grid").children().children().children().removeClass();
      $(".product-wrapper-grid").children().children().children().addClass("col-lg-6");
    }
  }

  public threeCol() {
    if ($('.product-wrapper-grid').hasClass("list-view")) {} else {
      $(".product-wrapper-grid").children().children().children().removeClass();
      $(".product-wrapper-grid").children().children().children().addClass("col-lg-4");
    }
  }

  public fourCol() {
    if ($('.product-wrapper-grid').hasClass("list-view")) {} else {
      $(".product-wrapper-grid").children().children().children().removeClass();
      $(".product-wrapper-grid").children().children().children().addClass("col-lg-3");
    }
  }

  public sixCol() {
    if ($('.product-wrapper-grid').hasClass("list-view")) {} else {
      $(".product-wrapper-grid").children().children().children().removeClass();
      $(".product-wrapper-grid").children().children().children().addClass("col-lg-2");
    }
  }

  // For mobile filter view
  public mobileFilter() {
    $('.collection-filter').css("left", "-15px");
  }

  // Infinite scroll
    public onScroll() {
        if (this.allItems.length > 0) {
            this.lastKey = _.last(this.allItems)['id'];
            if (this.lastKey != _.last(this.items)['id']) {
                this.finished = false
            }
            // If data is identical, stop making queries
            if (this.lastKey == _.last(this.items)['id']) {
                this.finished = true
            }
        }
        else
            this.finished = false
      if(this.products.length < this.allItems.length){  
         let len = this.products.length;
         for(var i = len; i < len+4; i++){
           if(this.allItems[i] == undefined) return true
             this.products.push(this.allItems[i]);
         }
      }
  }
  
  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
     this.sortByOrder = val;
     this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }

}
