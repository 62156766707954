import { Component, OnInit } from '@angular/core';
import { Product } from '../../shared/classes/product';
import { ProductsService } from '../../shared/services/products.service';
import { DataService } from 'src/app/services/httpServices';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-home-seven',
    templateUrl: './home-seven.component.html',
    styleUrls: ['./home-seven.component.scss']
})
export class HomeSevenComponent implements OnInit {

    public products: Product[] = [];

    constructor(private productsService: ProductsService, public dataService: DataService, private toastr: ToastrService) { }

    ngOnInit() {
        //this.productsService.getProducts().subscribe(product => {
        //    product.filter((item: Product) => {
        //        if (item.category == 'flower')
        //            this.products.push(item)
        //    })
        //});


        this.dataService.postFuncP('Urun/GetUserProductViewList', 30)
            .subscribe((data: any) => {
                if (data.resultType == 1) {

                    //for (var i = 0; i < data.innerData.length; i++) {
                    //    data.innerData[i].pictures = data.innerData[i].pictures.$values;
                    //}
                    this.products = data.innerData;
                }
                else if (data.resultType == 2 || data.resultType == 0) {
                    this.toastr.warning('', 'Uyarı');
                }
            },
                error => this.toastr.error('Hata Meydana Geldi. Lütfen Daha Sonra Tekrar Deneyiniz.', 'Hata'),
                () => '');


    }

}
